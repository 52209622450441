<template>
  <Sidemenu :menuItems="exampleMenuItems" />
</template>

<script>
import Sidemenu from '@dharmachakra/tbco-sidemenu/src/components/Sidemenu.vue'
import ExampleMenuItems from '@dharmachakra/tbco-sidemenu/src/example-menu-items'

export default {
  components: {
    Sidemenu
  },
  data() {
    return {
      exampleMenuItems: ExampleMenuItems()
    }
  }
}
</script>

<style scoped lang="scss">
h1 {
  margin: 0;
}

#page {
  max-width: 920px;
  margin: 0 auto;
  background: white;
  position: relative;
  height: 100vh;
}
</style>
